@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  @apply text-lg;
}

.projects img {
  width: 100%;
}

/* color */
.bg-primary {
  background: #1c3a4b; /* Darker blue tone */
}

.bg-secondery {
  background: #021f2d; /* Secondary blue tone */
}

.text-primary {
  color: #1c3a4b; /* Secondary blue tone */
}

.text-secondery {
  color: #021f2d; /* Secondary blue tone */
}

.bg-accent {
  background: #04728b; /* Accent light blue, matching the logo */
}

.text-accent {
  color: #4fb3ca; /* Light blue accent color */
}

nav li a {
  @apply px-4 py-5 text-lg;
}

nav li a:hover {
  color: #4fb3ca; /* Light blue accent color for hover */
}

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
}

/* hero */
h1 {
  font-family: "Pacifico", cursive;
  line-height: 1.5 !important;
}

/* Keyframes for animations */
@keyframes moveLeft {
  0% {
    transform: translateX(0) scale(1.1);
  }
  100% {
    transform: translateX(-100%) scale(0.9);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0) scale(1.1);
  }
  100% {
    transform: translateX(100%) scale(0.9);
  }
}

@keyframes showCardRight {
  0% {
    opacity: 0;
    transform: translateX(100%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes showCardLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes scaleIconGrowLeft {
  0% {
    transform: translate(0) scale(1);
  }
  100% {
    transform: translate(25%) scale(1.2);
  }
}

@keyframes scaleIconGrowRight {
  0% {
    transform: translate(0) scale(1);
  }
  100% {
    transform: translate(75) scale(1.2);
  }
}

/* Animation classes */
.animate-next {
  animation: moveLeft 1s ease-in-out forwards;
}

.animate-prev {
  animation: moveRight 1s ease-in-out forwards;
}

.animate-icon-grow-left {
  animation: scaleIconGrowLeft 1s ease-in-out forwards;
}

.animate-icon-grow-right {
  animation: scaleIconGrowRight 1s ease-in-out forwards;
}

/* Apply hide/show animations to cards */
.card-hide {
  animation: hideCard 1s ease-in-out forwards;
}

.card-show {
  animation: showCardRight 1s ease-in-out forwards;
}

/* Keyframes for animations */
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%); /* Move out of the view */
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0) scale(1.1);
  }
  100% {
    transform: translateX(-100%) scale(0.9); /* Smaller as it moves left */
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0) scale(1.1);
  }
  100% {
    transform: translateX(100%) scale(0.9); /* Smaller as it moves right */
  }
}

@keyframes showCardLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1.1); /* Center card becomes larger */
  }
}

@keyframes showCardRight {
  0% {
    opacity: 0;
    transform: translateX(100%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1.1); /* Center card becomes larger */
  }
}

/* Animation classes */
.animate-next {
  animation: moveLeft 1s ease-in-out forwards,
    showCardLeft 1s ease-in-out forwards;
}

.animate-prev {
  animation: moveRight 1s ease-in-out forwards,
    showCardRight 1s ease-in-out forwards;
}

.card-hide-left {
  animation: fadeOut 1s ease-in-out forwards;
}

.card-hide-right {
  animation: fadeOut 1s ease-in-out forwards;
}
